import React from "react"
//import {Link} from "gatsby"
//import { RiHeart2Line } from "react-icons/ri"; 


const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <p>© Alex Kilkka. All rights reserved.</p>
    </div>
  </footer>
)

export default Footer